.direct_call_dialog {
  position: absolute;
  width: 400px;
  height: 300px;
  top: calc(50% - 150px);
  left: calc(50% - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.direct_call_dialog_caller_name {
  font-size: 30px;
}

.direct_call_dialog_button_container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.direct_call_dialog_accept_button {
  border-radius: 50px;
  outline: none;
  border: 1px solid #e6e5e8;
  font-size: 16px;
  padding: 10px 10px;
  width: 120px;
  transition: 0.3s;
  color: #e6e5e8;
  background-color: #282C34;
}

.direct_call_dialog_accept_button:hover {
  background-color: #8a85ff;
  opacity: 0.5;
  color: black;
}

.direct_call_dialog_reject_button {
  border-radius: 50px;
  outline: none;
  border: 1px solid #e6e5e8;
  font-size: 16px;
  padding: 10px 10px;
  width: 120px;
  transition: 0.3s;
  color: #e6e5e8;
  background-color: #282C34;
}

.direct_call_dialog_reject_button:hover {
  background-color: #8a85ff;
  opacity: 0.5;
  color: black;
}

