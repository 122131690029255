.group_call_room_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group_call_title {
  font-size: 50px;
  margin-top: 50px;
}

.group_call_videos_container {
  width: 100%;
  height: 300px;
  margin-top: 15%;
  display: flex;
  justify-content: space-evenly;
}