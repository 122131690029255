.active_user_list_container {
  width: 100%;
  margin-top: 28%;
  background: #eee;
}

.active_user_list_item {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.active_user_list_item:hover {
  background-color: #8a85ff;
}

.active_user_list_image_container {
  width: 40px;
  margin-left: 15px;
  margin-right: 15px;
}

.active_user_list_text {
  font-size: 20px;
}

.active_user_list_image {
  width: 100%;
}